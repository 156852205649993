import { Route, Routes, useRoutes } from "react-router-dom";
import React from "react";
import Themeroutes from "./routes/Router";
import Starter from "./views/Starter";
import City from "./views/ui/City";
import FullLayout from "./layouts/FullLayout";

const App = () => {
  const routing = useRoutes(Themeroutes);

  return <div className="dark">{routing}</div>;
};

export default App;
