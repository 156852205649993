// import ProjectTables from "../../components/dashboard/ProjectTable";
import React from "react";

import {
  Row,
  Col,
  Table,
  Card,
  CardTitle,
  CardBody,
  FormGroup,
  Label,
  Input,
  Badge,
  Button,
  Alert,
} from "reactstrap";
import { useState, useEffect } from "react";
import Moment from "moment";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Filter } from "react-data-table-component-extensions/dist/ui";
import { CSVLink } from "react-csv";
const Booking = () => {
  const [row, setRow] = useState([]);
  const [city, setCity] = useState([]);
  const [tour, setTour] = useState([]);
  const [tourDataset, setTourDataset] = useState([]);
  const [route, setRoute] = useState([]);
  const [routeId, setRouteId] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [srchCity, setSrchCity] = useState("");
  const [srchRoute, setSrchRoute] = useState("");
  const [srchTour, setSrchTour] = useState("");
  const [dataSet, setdataSet] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [timeId, setTimeID] = useState("");
  const [places, setPlaces] = useState("");
  const [toDate, setToDate] = useState("");
  const [onSelect, setOnSelect] = useState(false);
  const Citypermission = sessionStorage.getItem("City");
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  const get = async () => {
    await fetch("https://tdcp.gop.pk/apis/list_bookings")
      .then((res) => res.json())
      .then((data) => {
        if (Citypermission === "All") {
          setRow(data.data);
          setdataSet(data.data);
        } else {
          setRow(
            data.data.filter((p) =>
              p.seats.some((s) => s.cityName === Citypermission)
            )
          );
          setdataSet(
            data.data.filter((p) =>
              p.seats.some((s) => s.cityName === Citypermission)
            )
          );
        }
        // setRow(data.data);
        // setdataSet(data.data);
        // console.log(data.data);
        //  console.log(data.data.map((s)=>s.seats?s.seats:''));
      });
  };
  const getTour = async () => {
    await fetch("https://tdcp.gop.pk/apis/get_routes")
      .then((res) => res.json())
      .then((data) => {
        setTourDataset(data.data);
        setTour(data.data);
      });
  };
  const getRoutes = async () => {
    try {
      console.log(routeId);
      await fetch("https://tdcp.gop.pk/apis/get_timings?routeId=" + routeId)
        .then((res) => res.json())
        .then((data) => setRoute(data.data));
      setRouteId("");
    } catch (e) {
      console.log();
    }
  };
  const getcity = async () => {
    await fetch("https://tdcp.gop.pk/apis/get_cities")
      .then((res) => res.json())
      .then((data) => {
        console.log(Citypermission);
        console.log(data.data.find((d) => d.name));
        if (Citypermission == "All") {
          console.log("All");
          setCity(data.data);
        } else if (data.data.filter((d) => d.name === Citypermission)) {
          const filter = data.data.filter((d) => {
            return d.name === Citypermission;
          });
          setCity(filter);
        }
      });
    // .then((data)=>setCity(data.data))
  };
  const handleRoute = (e) => {
    try {
      // console.log(e.target.value);
      var fil = route.find((t) => t.places === e.target.value);
      console.log(fil);
      setTimeID(fil.time_id);
      setPlaces(fil.places);
      // console.log(fil.time_id);
      // console.log(dataSet);
      setSrchRoute(e.target.value);
      if (srchRoute) {
        const filter = dataSet.filter((r) =>
          r.seats?.some(
            (s) =>
              s.places == e.target.value &&
              s.date >= fromDate &&
              s.date <= toDate
          )
        );
        setRow(filter);
      }
    } catch (e) {
      console.log(e);
    }
    // getRoutes();
    // setRouteId(e.target.value);
    // setCityId(e.target.value);
  };
  const handleSelect = async (e) => {
    setRouteId(e.target.value);
    setSrchTour(e.target.value);
    const filter = dataSet.filter((r) =>
      r.seats?.some(
        (s) =>
          s.routeId == e.target.value && s.date >= fromDate && s.date <= toDate
      )
    );
    setRow(filter);
    if (e.target.value == "0") {
      setRow(dataSet);
    }
    try {
      if (e.target.value == "0") {
        setRoute(route);
      } else {
        await fetch(
          "https://tdcp.gop.pk/apis/get_timings?routeId=" + e.target.value
        )
          .then((res) => res.json())
          .then((data) => setRoute(data.data));
        setRouteId("");
      }
    } catch (e) {
      console.log(e);
    }
    // getRoutes();
    // setCityId(e.target.value);
  };
  const clearFilter = () => {
    setRow(dataSet.sort().reverse());
    setSrchCity("");
    setSrchRoute("");
    setSrchTour("");
    window.location.reload();
  };
  const exportData = () => {};
  const deleteData = async () => {
    if (timeId == "") {
      alert("Select a Route");
      return;
    }
    console.log(timeId);
    if (window.confirm("All the Pending Entries will be Deleted " + places)) {
      await fetch(
        "https://tdcp.gop.pk/apis/delete_pending?from=" +
          fromDate +
          "&to=" +
          toDate +
          "&time=" +
          timeId
      )
        .then((res) => res.json())
        .then((data) => {
          data.status == 200
            ? alert("Deleted Succesfully")
            : alert(data.message);
        });
      window.location.reload();
    }
    //  get();
    // setSrchCity('');
  };

  const hanldeUsed = (e) => {
    const val = e.target.value;
    // console.log(val);
    if (timeId == "") {
      return alert("Select a Boat and Date");
    }
    if (val === "Used") {
      const filter = dataSet.filter((r) => {
        return (
          r.validate === 1 &&
          r.seats.some(
            (s) => s.date >= fromDate && s.date <= toDate && s.timeId == timeId
          )
        );
      });
      setRow(filter);
    } else {
      const filter = dataSet.filter((r) => {
        return (
          r.validate === 0 &&
          r.seats.some(
            (s) => s.date >= fromDate && s.date <= toDate && s.timeId == timeId
          )
        );
      });
      setRow(filter);
    }
  };
  const hanldeCash = (e) => {
    if (timeId == "") {
      return alert("Select a Boat and Date");
    }
    const val = e.target.value;
    console.log(val);
    if (val === "Cash") {
      const filter = dataSet.filter((r) => {
        return (
          r.paymentMethod === val &&
          r.seats.some(
            (s) => s.date >= fromDate && s.date <= toDate && s.timeId == timeId
          )
        );
      });
      setRow(filter);
    } else {
      const filter = dataSet.filter((r) => {
        return r.paymentMethod === val;
      });
      setRow(filter);
    }
  };
  const hanldePending = (e) => {
    const val = e.target.value;
    console.log(places + "Places");

    const filter = dataSet.filter((item) => {
      console.log(timeId + "Time");
      if (val == "" && places == "") {
        console.log(" NOHere" + srchTour);
        return item;
      } else if (
        item.seats.some(
          (s) =>
            s.status === val &&
            s.places === places &&
            s.date >= fromDate &&
            s.date <= toDate
        )
      ) {
        console.log("Here in srch City");
        console.log(item);
        return item;
      }
    });
    console.log(filter);
    if (filter) {
      setRow(filter);
      // setPlaces('');
    }

    // setPlaces('');
  };
  const handleSrchCity = (e) => {
    setSrchCity(e.target.value);
    const filter = dataSet.filter((item) => {
      if (fromDate == "") {
        return item.seats.some((s) => s.cityName === e.target.value);
      } else if (
        item.seats.some(
          (s) =>
            s.cityName === e.target.value &&
            s.date >= fromDate &&
            s.date <= toDate
        )
      ) {
        return item;
      }
    });

    setRow(filter);
    const tourFilter = tourDataset.filter((t) => t.cityName == e.target.value);
    setTour(tourFilter);
    console.log(tourFilter);
  };
  const handleDate = (date) => {
    console.log(date.selection.startDate);
    console.log(date.selection.endDate);
    const filter = dataSet.filter((item) => {
      if (date.selection.startDate == "") {
        return item;
      } else if (
        item.seats.some(
          (s) =>
            // console.log(new Date(s.date)=== new Date(fromDate)) Moment(e.target.value).format('DD-MM-YYYY')
            // new Date(s.date)>=Moment(fromDate).format('DD-MM-YYYY')&& new Date(s.date)<=Moment(toDate).format('DD-MM-YYYY')
            s.date >= Moment(date.selection.startDate).format("D/M/YYYY") &&
            s.date <= Moment(date.selection.endDate).format("D/M/YYYY")
        )
      ) {
        // console.log("Here in srch City");
        // console.log(item);

        // console.log(fromDate + " her " + toDate);
        return item;
      }
    });
    if (filter) {
      setRow(filter);
      setFromDate(Moment(date.selection.startDate).format("D/M/YYYY"));
      setToDate(Moment(date.selection.endDate).format("D/M/YYYY"));
      // setToDate(Moment(date.selection.endDate).format("D/M/YYYY"));
      setOnSelect(!onSelect);
    }
  };

  const handleConfirm = async (id) => {
    // alert("Here"+id);

    // https://tdcp.gop.pk/apis/update_status?validate=confirmed&id=2

    await fetch(
      "https://tdcp.gop.pk/apis/update_status?validate=confirm&id=" + id
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        data.status == 200
          ? alert("Confirmed Succesfully")
          : alert(data.message);
      });
    //.then(data => console.log(data));
    // alert("Deleted Succesfully");
    get();
  };
  const DeleteBooking = async (id) => {
    // alert("Here"+id);

    // https://tdcp.gop.pk/apis/update_status?validate=confirmed&id=2

    await fetch("https://tdcp.gop.pk/apis/delete_bookings?id=" + id)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        data.status == 200 ? alert("Deleted Succesfully") : alert(data.message);
      });
    //.then(data => console.log(data));
    // alert("Deleted Succesfully");
    get();
    setToDate("");
    setFromDate("");
  };
  useEffect(() => {
    get();
    getcity();
    getTour();
    //console.log(row);
  }, []);
  const columns = [
    {
      name: <b>ID</b>,
      width: "80px",
      selector: (row) => (
        <Link
          to={"https://sightseeing.tdcponline.com/invoice?id=" + row.booking_id}
          target="_blank"
        >
          {row.booking_id}
        </Link>
      ),
      sortable: true,
    },
    {
      name: <b>Name</b>,
      width: "130px",
      wrap: true,
      selector: (row) => row.customerName + "  " + row.customerPhone,
      sortable: true,
    },
    //  {
    //     name: <b>City</b>,
    //     width:'115px',
    //     wrap: true,
    //     selector: row => row.seats.map((s,i)=>i===0?s.cityName:''),
    //     sortable: true,
    // },
    {
      name: <b>Route</b>,
      wrap: true,
      width: "130px",
      selector: (row) => row.seats.map((s, i) => (i === 0 ? s.routeName : "")),
      sortable: true,
    },
    {
      name: <b>Time</b>,
      wrap: true,
      width: "90px",
      selector: (row) => row.seats.map((s, i) => (i == 0 ? s.time_from : "")),
      sortable: true,
    },
    // {
    //     name: <b>Terminal</b>,
    //     wrap: true,
    //     width:"150px",
    //     selector: row => row.seats.map((s,i)=>i===0?s.terminal:''),
    //     sortable: true,
    // },
    {
      name: <b>Seat</b>,
      wrap: true,
      width: "80px",
      selector: (row) => row.seats.map((s) => s.seatNo + " "),
      sortable: true,
    },
    {
      name: <b>Fare</b>,
      wrap: true,
      width: "80px",
      selector: (row) => {
        const tot = [];
        row.seats.map((s, i) => tot.push(s.price) || 0);
        // console.log(tot);
        const fin = tot.reduce((t, a) => t + a, 0);
        return fin;
      },
      sortable: true,
    },
    {
      name: <b>Valid</b>,
      wrap: true,
      width: "62px",
      selector: (row) =>
        row.validate == 1 ? (
          <b class="bi bi-check-lg"></b>
        ) : (
          <i class="bi bi-x"></i>
        ),
      sortable: true,
    },
    {
      name: <b>Payment Method</b>,
      wrap: true,
      width: "90px",
      selector: (row) => row.paymentMethod,
      sortable: true,
    },
    {
      name: <b>Payment Status</b>,
      wrap: true,
      width: "90px",
      selector: (row) =>
        row.seats.map((s, i) => {
          return i === 0 ? (
            s.status == "pending" ? (
              <b color="danger">{s.status}</b>
            ) : (
              <i>{s.status}</i>
            )
          ) : (
            " "
          );
        }),
      sortable: true,
    },
    {
      name: <b>Confirm!</b>,
      cell: (row) => (
        <Button
          size="sm"
          color="danger"
          className="bi-check-square"
          onClick={(e) => handleConfirm(row.booking_id)}
          id={row.booking_id}
        ></Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: <b>Delete</b>,
      cell: (row) => (
        <Button
          size="sm"
          color="danger"
          className="bi-trash"
          disabled={
            Citypermission == "All" ||
            row.seats.find((s) => s.status == "pending")
              ? false
              : true
          }
          onClick={(e) => DeleteBooking(row.booking_id)}
          id={row.booking_id}
        ></Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  // const columns = [
  //   {
  //     name: "booking_id",
  //     selector: "booking_id",
  //     sortable: true
  //   },
  //   {
  //     name: "customerAddress",
  //     selector: "customerAddress",
  //     sortable: true
  //   },
  //   {
  //     name: "customerEmail",
  //     selector: "customerAddress",
  //     sortable: true,
  //   //  cell: d => <span>{d.paymentMethod}</span>
  //   },
  //   {
  //     name: "customerPhone",
  //     selector: "customerPhone",
  //     sortable: true
  //   }
  // ];
  const headers = [
    { label: "Booking id", key: "booking_id" },
    { label: "Name", key: "customerName" },
    { label: "Route", key: "routeName" },
    { label: "time", key: "time_from" },
    { label: "Seat", key: "seatNo" },
    { label: "Price", key: "price" },
    { label: "Valid", key: "validate" },
    { label: "Payment", key: "paymentMethod" },
    { label: "Status", key: "status" },
    { label: "Terminal", key: "terminal" },
    { label: "Date", key: "date" },
  ];
  const flattenData = row.flatMap((item) => {
    return item.seats.map((subItem) => {
      return {
        booking_id: item.booking_id,
        customerName: item.customerName,
        customerEmail: item.customerEmail,
        paymentMethod: item.paymentMethod,
        validate: item.validate,
        customerPhone: item.customerPhone,
        price: subItem.price,
        seatNo: subItem.seatNo,
        routeName: subItem.routeName,
        terminal: subItem.terminal,
        cityName: subItem.cityName,
        places: subItem.places,
        time_from: subItem.time_from + " " + subItem.time_to,
        time_to: subItem.time_to,
        date: subItem.date,
        status: subItem.status,
      };
    });
  });

  // const flattenData = row.map((item) => {
  //   const flattenedItem = { ...item };
  //   item.seats.forEach((item, index) => {
  //     console.log(item.index);
  //     flattenedItem[`seats_${index}_itemName`] = item.price;
  //     flattenedItem[`seats_${index}_quantity`] = item.seatNo;
  //   });
  //   delete flattenedItem.seats;
  //   return flattenedItem;
  // });
  const tableData = {
    columns,
    row,
  };
  const cus = {
    position: "absolute",
    zindex: 1000,
    top: 4,
    left: 0,
  };
  function flat(array) {
    var result = [];
    array.forEach(function (a) {
      result.push(a);
      // console.log(a.seats);
      if (Array.isArray(a.seats)) {
        // console.log(a.children);
        result = result.concat(flat(a.seats));
      }
    });
    console.log(result);
    return result;
  }
  return (
    <Row>
      {/* {console.log(flattenData)} */}
      {/* {console.log(flat(row))} */}
      <Col lg="12">
        <Card>
          <Row className="p-1 mb-0">
            <Col style={{ position: "relative" }}>
              <Label>Filter Date</Label>
              <Button
                size="sm"
                className="w-100"
                color="danger"
                onClick={(e) => setOnSelect(!onSelect)}
              >
                Select Date Ranges{" "}
              </Button>
              {onSelect ? (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 1000,
                    top: 4,
                    left: 0,
                  }}
                >
                  <DateRange
                    onSelect={onSelect}
                    ranges={[selectionRange]}
                    onChange={handleDate}
                    singleDateRange={true}
                  />
                </div>
              ) : (
                ""
              )}

              {/* <Label>From </Label>
      <Input type='date' onChange={(e)=>setFromDate(Moment(e.target.value).format('DD-MM-YYYY'))}/> */}
            </Col>
            {/* <Col>
      <Label>To </Label>
      <Input type='date'  onChange={(e)=>dateFilter(e)} />
        </Col> */}
            <Col>
              <FormGroup>
                <Label for="exampleSelect">Select City</Label>
                <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
                  onChange={handleSrchCity}
                >
                  <option value="">Select</option>
                  {city.map((c) => {
                    return (
                      <option key={c.id} value={c.name}>
                        {c.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="exampleSelect">Select Tour</Label>
                <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
                  onChange={handleSelect}
                >
                  <option value="0">Select</option>
                  {tour.map((c) => {
                    return (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="exampleSelect">Select Route</Label>
                <Input
                  id="route"
                  name="route"
                  type="select"
                  onChange={handleRoute}
                >
                  <option>Select</option>
                  {/* <option>Select</option> */}
                  {/* {console.log(route)} */}
                  {route.map((c) => {
                    // console.log(c)
                    return (
                      <option key={c.time_id} value={c.places}>
                        {c.time_from}{" "}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row className="p-1 mb-0">
            <Col>
              {/* <Label>Status</Label> */}
              <Input id="pending" type="select" onChange={hanldePending}>
                <option value={"pending"}>Select Status</option>
                <option value={"pending"}>Pending</option>
                <option value={"confirm"}>Confirm</option>
              </Input>
            </Col>
            <Col>
              <Input id="exampleSelect" type="select" onChange={hanldeUsed}>
                <option value={"pending"}>Select Used </option>
                <option value={"Used"}>Used</option>
                <option value={"Not Used"}>Not Used</option>
              </Input>
            </Col>
            <Col>
              {/* <Label>Payment</Label> */}
              <Input id="exampleSelect" type="select" onChange={hanldeCash}>
                <option value={"pending"}>Select Payment </option>
                <option value={"Cash"}>cash</option>
                <option value={"credit/debit"}>credit/debit</option>
              </Input>
            </Col>

            <Col className="button-group text-end">
              <Button color="danger" onClick={deleteData}>
                Delete
              </Button>
              {/* <Button  color="danger" onClick={exportData}>Export</Button> */}
              <Button color="danger" onClick={clearFilter}>
                Clear
              </Button>
              <CSVLink
                headers={headers}
                data={flattenData}
                filename={new Date()}
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <Button color="danger" onClick={exportData}>
                  <i class="bi bi-download"></i>
                </Button>
                {row ? "" : ""}
              </CSVLink>
            </Col>
          </Row>
          <CardTitle tag="h6" className="border-bottom p-3 mb-0">
            <Col>
              <b className="bi bi-card-text me-2 text-start">
                {" "}
                Booking Detail {fromDate} {toDate}
              </b>

              <div className="text-end"></div>
            </Col>
          </CardTitle>
          <CardBody className="">
            <Table bordered hover>
              {/* <DataTableExtensions {...tableData}> */}
              <DataTable
                columns={columns}
                data={row.sort()}
                className="react-dataTable"
                pagination
                highlightOnHover
                noHeader
                responsive
              />
              {/* </DataTableExtensions> */}
              {/* {row} */}
              {/* <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>Email</th>
                  <th>Seats</th>
                </tr>
              </thead>
              <tbody>
              {console.log(row)}
                {
                  
                  row.map((route)=>{
                    return(
                    <tr key={route.booking_id}>
                    <td>{route.booking_id}</td>
                    <td>{route.customerName}</td>
                    <td>{route.customerPhone}</td>
                    <td>{route.customerAddress}</td>
                    <td>{route.customerEmail}</td>
                    <td>{route.seats.length}</td>
                  </tr>
                  
                  )})
                }
              </tbody> */}
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Booking;
