import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import Chart from "react-apexcharts";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { LOCAL_URL } from "../../constant/constant";
import html2canvas from "html2canvas";

const SalesChartP = () => {
  const [services, setServices] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dashboardRef = useRef(null);

  const getTour = async () => {
    try {
      let url = `${LOCAL_URL}patriata_api/get_dashboard_stats?startDate=${startDate}&endDate=${endDate}`;
      let response = await fetch(url);
      const data = await response.json();
      setServices(data.data);
    } catch (error) {
      console.error("Error fetching reservations:", error);
    }
  };
  useEffect(() => {
    getTour();
  }, []);
  useEffect(() => {
    getTour();
  }, [startDate, endDate]);

  const processData = () => {
    let totalRevenue = 0;
    let totalRefund = 0;
    let totalTickets = 0;
    let totalFreeTickets = 0;
    let totalRefundPrice = 0;

    services.forEach((service) => {
      totalFreeTickets += service?.pricing
        ?.filter((i) => i.price === 0)
        .reduce((sum, it) => sum + it.total_tickets, 0);
      totalRefundPrice += service?.pricing?.reduce(
        (sum, item) => sum + item.total_refund * item.price,
        0
      );
      service.pricing?.forEach((item) => {
        totalTickets += item.total_tickets || 0;
        totalRevenue += item.total_price || 0;
        totalRefund += item.total_refund || 0;
      });
    });

    return {
      totalRevenue,
      totalRefund,
      totalTickets,
      totalFreeTickets,
      netRevenue: totalRevenue - totalRefundPrice,
      totalRefundPrice,
    };
  };

  const {
    totalRevenue,
    totalRefund,
    netRevenue,
    totalTickets,
    totalFreeTickets,
    totalRefundPrice,
  } = processData();

  const downloadPDF = async () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const input = dashboardRef.current;

    // Scroll fix: Wait until all images are fully loaded
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Capture each graph separately to avoid cutoff
    const graphElements = input.querySelectorAll(".graph-container");

    for (let i = 0; i < graphElements.length; i++) {
      const canvas = await html2canvas(graphElements[i], { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 190;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      if (i > 0) pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
    }

    pdf.save("dashboard_report.pdf");
  };

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <Row>
              <div className="col-md-3">
                <label className="form-label">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label className="form-label">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              <div className="col-md-3 d-flex flex-column justify-content-end">
                {/* Empty label to match height */}
                <label className="form-label invisible">Placeholder</label>
                <Button color="primary" onClick={downloadPDF}>
                  Download Report
                </Button>
              </div>
            </Row>
            <div id="dashboard-content" ref={dashboardRef}>
              <Row className="mt-3 graph-container">
                <Col md="3">
                  <Card className="p-3">
                    <h6>Total Reservations ({totalTickets} Tickets)</h6>
                    <h4>RS {totalRevenue}</h4>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="p-3">
                    <h6>Total Refund ({totalRefund} Tickets)</h6>
                    <h4>RS {totalRefundPrice}</h4>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="p-3">
                    <h6>Free Tier Tickets ({totalFreeTickets} Tickets)</h6>
                    <h4>RS {0}</h4>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="p-3">
                    <h6>
                      Net Revenue (
                      {totalTickets - totalRefund - totalFreeTickets} Tickets)
                    </h6>
                    <h4>RS {netRevenue}</h4>
                  </Card>
                </Col>
                {services.length && (
                  <BarChartExample
                    key={services[0].name}
                    data={services[0]}
                    index={0}
                  />
                )}
              </Row>
              {services.length &&
                services.map(
                  (s, i) =>
                    i > 0 && <BarChartExample key={s.name} data={s} index={i} />
                )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const BarChartExample = ({ data, index }) => {
  console.log("Chart Data:", data);

  // Extract categories and corresponding data
  const categories = data?.pricing?.map((item) => item.desc) || [];
  const ticketData = data?.pricing?.map((item) => item.total_tickets) || [];
  const totalRevenue = data?.pricing?.reduce(
    (sum, item) => sum + item.total_price,
    0
  );
  const totalRefund = data?.pricing?.reduce(
    (sum, item) => sum + item.total_refund,
    0
  );
  const totalRefundPrice = data?.pricing?.reduce(
    (sum, item) => sum + item.total_refund * item.price,
    0
  );
  const freeTierTickets = data?.pricing
    ?.filter((item) => item.price === 0)
    .reduce((sum, item) => sum + item.total_tickets, 0);
  const netRevenue = totalRevenue - totalRefundPrice;
  const totalTickets = ticketData.reduce((sum, val) => sum + val, 0);

  const options = {
    chart: { type: "bar" },
    xaxis: { categories },
  };

  const series = [
    {
      name: "Total Tickets",
      data: ticketData,
    },
  ];

  return (
    <div className={`mb_3 ${index !== 0 && "graph-container"}`}>
      <h3 className="mb-4 text-xl fw-bold">{data?.name}</h3>

      <Row className="mt-3">
        <Col md="3">
          <Card className="p-4 text-center shadow-lg">
            {/* Total reservation (x Tickets) */}
            <h6 className="text-lg fw-bold">
              Total Reservations
              <span className="text-muted">({totalTickets} Tickets)</span>
            </h6>
            <h4>RS {totalRevenue}</h4>
          </Card>
        </Col>
        <Col md="3">
          <Card className="p-4 text-center shadow-lg">
            {/* Total refund (x Tickets) */}
            <h6 className="text-lg fw-bold">
              Total Refund{" "}
              <span className="text-muted">({totalRefund} Tickets)</span>
            </h6>
            <h4>RS {totalRefundPrice}</h4>
          </Card>
        </Col>
        <Col md="3">
          <Card className="p-4 text-center shadow-lg">
            {/* Free tier (x Tickets) price=>0 */}
            <h6 className="text-lg fw-bold">
              Free Tier Tickets{" "}
              <span className="text-muted">({freeTierTickets} Tickets)</span>
            </h6>
            <h4>RS {0}</h4>
          </Card>
        </Col>
        <Col md="3">
          <Card className="p-4 text-center shadow-lg">
            {/* Net Revenue (x Tickets) */}
            <h6 className="text-lg fw-bold">
              Net Revenue{" "}
              <span className="text-muted">
                ({totalTickets - freeTierTickets - totalRefund} Tickets)
              </span>
            </h6>
            <h4>RS {netRevenue}</h4>
          </Card>
        </Col>
      </Row>

      <Chart
        options={options}
        series={series}
        type="bar"
        height="350"
        className="mt-4"
      />
    </div>
  );
};

export default SalesChartP;
