import React from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import { Button, Label } from "reactstrap";
import { LOCAL_URL } from "../../constant/constant";

function LoginUI() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const loginHandler = async () => {
    if (!username || !password) {
      alert("Enter an Email and Password");
      return;
    }
    if (!isValidEmail(username)) {
      alert("Enter a Valid Email Address");
      return;
    }
    try {
      let url =
        LOCAL_URL +
        "patriata_api/get_login?email=" +
        username +
        "&pass=" +
        password;
      let response = await fetch(url);
      let data = await response.json();
      console.log("Fetched Data:", data);

      if (data.data.length > 0) {
        const fil = data.data[0];
        if(fil.name){
          console.log("f", fil)
          sessionStorage.setItem("Route", fil.service);
          sessionStorage.setItem("City", fil.city);
          sessionStorage.setItem("auth", fil.name);
          
          // Debugging session storage
          console.log("Session Storage:", {
            Route: sessionStorage.getItem("Route"),
            City: sessionStorage.getItem("City"),
            Auth: sessionStorage.getItem("auth"),
          });
          navigate("/starter");
          window.location.reload();
        }else {
          alert("Invalid Details");
        }

      } else {
        alert("Invalid Details");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Something went wrong! Please try again.");
    }
  };

  return (
    <MDBContainer
      fluid
      className="d-flex flex-column align-items-center justify-content-center vh-100"
    >
      {/* Logo at the Top */}
      <div className="text-center mb-3">
        <img src="/logo.png" alt="Logo" width={380} />
      </div>

      <MDBRow className="d-flex justify-content-center align-items-center w-100">
        <MDBCol md="8" lg="5">
          <MDBCard className="p-3 shadow-lg border-0">
            <MDBCardBody className="d-flex flex-column align-items-center">
              <h4 className="fw-bold mb-2">Login</h4>
              <p
                className="text-muted text-center mb-3"
                style={{ fontSize: "14px" }}
              >
                Enter your credentials to continue
              </p>

              <Label className="w-100 text-start" style={{ fontSize: "14px" }}>
                Email
              </Label>
              <MDBInput
                wrapperClass="mb-3 w-100"
                onChange={(ev) => setUsername(ev.currentTarget.value)}
                id="formControlLg"
                type="email"
                size="md"
              />

              <Label className="w-100 text-start" style={{ fontSize: "14px" }}>
                Password
              </Label>
              <MDBInput
                onChange={(ev) => setPassword(ev.currentTarget.value)}
                wrapperClass="mb-3 w-100"
                id="formControlLg"
                type="password"
                size="md"
              />

              <Button
                className="w-100 button_green"
                color="primary"
                size="md"
                onClick={loginHandler}
              >
                Login
              </Button>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default LoginUI;
