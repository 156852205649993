import React from "react";

import { Card, CardBody, CardSubtitle, CardTitle, Row, Col } from "reactstrap";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
const SalesChartB = () => {
  const [sale, setTotSale] = useState([]);
  const [cityTotals, setCityTotals] = useState({});
  const totSale = [];
  const [row, setRow] = useState([]);
  const get = async () => {
    // fetch('https://fakestoreapi.com/products')
    await fetch("https://tdcp.gop.pk/boating_apis/list_bookings")
      .then((res) => res.json())
      .then((data) => setRow(data.data));
    cityWiseTotal();
  };
  function flat(array) {
    var result = [];
    array.forEach(function (a) {
      result.push(a);
      // console.log(a.seats);
      if (Array.isArray(a.seats)) {
        // console.log(a.children);
        result = result.concat(flat(a.seats));
      }
    });

    //console.log(result);
    return result;
  }
  const cityWiseTotal = () => {
    const dat = flat(row);

    const cityTotalss = dat.reduce((totals, item) => {
      if (item.cityName === item.cityName) {
        totals[item.cityName] =
          (totals[item.cityName] || 0) + (item.price || 0);
      }

      return totals;
    }, {});
    console.log(cityTotalss);
    return cityTotalss;
    // setCityTotals(cityTotalss);
    console.log("1");
    console.log(cityTotals);
  };
  useEffect(() => {
    get();
  }, []);
  //Cal total Sales
  const detail = cityWiseTotal();
  //setCityTotals(detail);
  // console.log(detail);

  const seats = [];
  const tot = 0;
  const totSales = () => {
    // console.log(row);
    // row?.map((r) => r.seats?.map((s) => totSale.push(Number(s.price || 0))));
    // // console.log(totSale);
    // const tot = totSale.reduce((a, t) => a + t, 0);
    // return tot;
  };
  // const totalSeat = () => {
  //   console.log("Seats Length");
  //   const data = row;
  //   data?.map((r) => seats.push(Number(r.seats.length)));
  //   const totSeats = seats.reduce((a, t) => a + t, 0);
  //   console.log(totSeats);
  //   return totSeats;
  // };

  //  console.log(tot);
  //Cal total Seats
  let service = [];
  let val = [];
  const renderDetails = () => {
    return Object.entries(detail).map(([location, value], index) => {
      if (location !== "undefined") {
        service.push(location || 0);
        val.push(value || 0);
        return (
          <Col key={index} md="4">
            <h6>{location}</h6>
            <h4 className="mb-0 fw-bold">{value}</h4>
          </Col>
        );
      }
      return null; // Skip rendering for entries with undefined values
    });
  };
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 4,
      colors: ["transparent"],
    },
    legend: {
      show: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        borderRadius: 2,
      },
    },
    colors: ["#0d6efd", "#009efb", "#6771dc"],
    xaxis: {
      categories: service,
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          plotOptions: {
            bar: {
              columnWidth: "60%",
              borderRadius: 7,
            },
          },
        },
      },
    ],
  };

  const series = [
    {
      name: "2023",
      data: val,
    },
    // {
    //   name: "2023",
    //   data: [tot - 40, tot],
    // },
  ];

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <CardTitle tag="h5">Booking Summary Boating service</CardTitle>
            <CardSubtitle className="text-muted" tag="h6">
              Booking Report
            </CardSubtitle>
            <div className="bg-primary text-danger my-3 p-3 rounded">
              <Row>
                <Col md="4">
                  <h6>Total Services</h6>
                  <h4 className="mb-0 fw-bold">{Object.keys(detail).length}</h4>
                </Col>
                <Col md="4">
                  <h6>Grand Booking</h6>
                  <h4 className="mb-0 fw-bold">
                    {" "}
                    {Object.values(detail).reduce(
                      (acc, value) => acc + value,
                      0
                    )}
                  </h4>
                </Col>

                {renderDetails()}
              </Row>
            </div>
            <Chart options={options} series={series} type="area" height="279" />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default SalesChartB;
