import { Button, Nav, NavItem } from "reactstrap";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const navigation = [
  {
    title: "Sight Seeing",
    desc:"Sight Seeing",
    icon: "bi bi-bus-front-fill",
    subdivision: [
      { title: "Dashboard", href: "/starter", icon: "bi bi-speedometer2" },
      { title: "City", href: "/city", icon: "bi bi-c-circle-fill" },
      { title: "Add Tour", href: "/forms", icon: "bi bi-bus-front" },
      { title: "All Tours", href: "/table", icon: "bi bi bi-card-list" },
      { title: "View Booking", href: "/booking", icon: "bi bi-layout-split" },
      { title: "Create Booking", href: "/create", icon: "bi bi bi-building-add" },
    ],
  },
  {
    title: "Patriata ChairLift",
    desc:"TDCP Resort Patriata",
    icon: "bi bi-ethernet",
    subdivision: [
      { title: "Dashboard", href: "/starter", icon: "bi bi-speedometer2" },
      { title: "City", href: "/pcity", icon: "bi bi-c-circle-fill" },
      { title: "Add Facility", href: "/pform", icon: "bi bi-bus-front" },
      { title: "All Facility", href: "/ptour", icon: "bi bi bi-card-list" },
      { title: "All Reservations", href: "/pbooking", icon: "bi bi-layout-split" },
      // { title: "New Reservation", href: "/pcreate", icon: "bi bi bi-building-add" },
    ],
  },
  {
    title: "Boating Service",
    desc:"Boating Service",
    icon: "bi bi-tsunami",
    subdivision: [
      { title: "Dashboard", href: "/starter", icon: "bi bi-speedometer2" },
      { title: "City", href: "/bcity", icon: "bi bi-c-circle-fill" },
      { title: "Add Service", href: "/bforms", icon: "bi bi-bus-front" },
      { title: "All Services", href: "/btable", icon: "bi bi bi-card-list" },
      { title: "All Reservations", href: "/bbooking", icon: "bi bi-layout-split" },
      { title: "New Reservation", href: "/bcreate", icon: "bi bi bi-building-add" },
    ],
  },
];

const Sidebar = () => {
  const location = useLocation();
  const Route = sessionStorage.getItem("Route");

  // Ensure the active dropdown remains open based on session storage
  const [activeNavItem, setActiveNavItem] = useState(Route);

  const toggleActiveNavItem = (title) => {
    setActiveNavItem(title === activeNavItem ? null : title);
  };

  const showMobileMenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const filteredNavigation = navigation.filter((n) => 
    Route === "All" || Route === n.title
  );

  return (
    <div>
      <div className="d-flex">
        <Button color="white" className="ms-auto text-black d-lg-none" onClick={showMobileMenu}>
          <i className="bi bi-x"></i>
        </Button>
      </div>
      <div className="p-3 mt-2">
        <Nav vertical className="sidebarNav">
          {filteredNavigation.map((navi, index) => (
            <NavItem key={index} className="sidenav-bg">
              <a
                href={navi.href}
                className={`sidebar-link ${location.pathname === navi.href ? "active-tab" : ""}`}
                onClick={() => toggleActiveNavItem(navi.title)}
              >
                <i className={navi.icon}></i>
                <span className="ms-3 d-inline-block">{navi.desc}</span>
              </a>

              {navi.subdivision && activeNavItem === navi.title && (
                <Nav vertical className="sidebarNav">
                  {navi.subdivision.map((subNavItem, subIndex) => (
                    <NavItem key={subIndex} className="sidenav-bg">
                      <a
                        href={subNavItem.href}
                        className={`sidebar-link ${location.pathname === subNavItem.href ? "active-tab" : ""}`}
                      >
                        <i className={subNavItem.icon}></i>
                        <span className="ms-3 d-inline-block">{subNavItem.title}</span>
                      </a>
                    </NavItem>
                  ))}
                </Nav>
              )}
            </NavItem>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
